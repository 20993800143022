import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Code from "./Code";

ReactDOM.render(
 <React.StrictMode>
  <Router>
   <Switch>
    <Route
     path="/p/:product"
     component={(props) => {
      console.log(props.match.params.product);
      window.location.href = `https://heretorecord.com/product/${props.match.params.product}`;
      return null;
     }}
    />
    {/* <Route path="/:code">
     <Code />
    </Route> */}
    <Route path="/:code">
     <Code />
    </Route>
    <Route path="/">
     <App />
    </Route>
   </Switch>
  </Router>
 </React.StrictMode>,
 document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
