import { useEffect, useState } from "react";
import "./App.css";

function App() {
 const [notWorking, setNotWorking] = useState(false);

 useEffect(() => {
  setTimeout(() => {
   setNotWorking(true);
  }, 5000);
 }, []);

 return (
  <div className="App">
   <header className="App-header">
    <h1>{!notWorking ? "Redirecting..." : "Oh no..."}</h1>
    {notWorking && (
     <p>
      Something's not right. Double-check your link or{" "}
      <a href="https://heretorecord.com/">go here</a>.
     </p>
    )}
   </header>
  </div>
 );
}

export default App;
