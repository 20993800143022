import { Redirect, useParams } from "react-router-dom";
import "./App.css";

function Code() {
 const { code } = useParams();
 if (code.length === 6) {
  window.location.replace(`https://cloud.h2r.graphics/code?c=${code}`);
 }
 return <Redirect to="/" />;
}

export default Code;
